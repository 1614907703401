@import '../colors.scss';
@import '../brakepoint.scss';

.section-hero{

    @include responsive(map-get($breakpoints, md)){
        margin-top: 5rem;
        margin-bottom: 3rem !important;
        &>div{
            width: 100%;
        }
        h2{
            margin-bottom: 1rem;
        }
        .label{
            margin-bottom: 1rem;
            display: block;
        }
        a{
            margin: 0 auto;
            margin-left: 0;
        }
    }

    @include responsive(map-get($breakpoints, sm)){
        margin-top: 5rem;
        margin-bottom: 3rem !important;
        &>div{
            width: 100%;
        }
        h2{
            margin-bottom: 1rem;
        }
        .label{
            margin-bottom: 1rem;
            display: block;
        }
        a{
            margin: 0 auto;
            margin-left: 0;
        }
    }
}

.hero {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include responsive(map-get($breakpoints, sm)){
        min-height: 36vh;
    }
    h1 {
        border: 1px solid $white;
        color: $white;
        border-radius: 22rem;
        border-radius: 22rem;
        text-transform: uppercase;
        vertical-align: middle;
        line-height: 100%;
        display: flex;
        align-items: center;
        padding: 1.5rem 1.8rem;
        width: fit-content;
        font-size: 5.4vw;
        margin-bottom: 3vw;

        @include responsive(map-get($breakpoints, xl)){
            font-size: 4rem;
            padding: 1.5rem 1.4rem;
            margin-bottom: 1.4rem;
            img{
                width: 70px;
            }
        } 


        @include responsive(map-get($breakpoints, md)){
            font-size: 4rem;
            padding: 1.5rem 1.4rem;
            margin-bottom: 1.4rem;
            img{
                width: 70px;
            }
        } 

        @include responsive(map-get($breakpoints, sm)){
            font-size: 1.8rem;
            padding: 1rem 0.8rem;
            margin-bottom: 1rem;
            img{
                width: 32px;
            }
        }

        img{
            animation: rotate-image 3s linear infinite;
        }

        &:nth-child(odd) {
            img {
                margin: 1rem;

                @include responsive(map-get($breakpoints, md)){
                    margin-right: 0.5rem;

                } 
                @include responsive(map-get($breakpoints, sm)){
                    margin-right: 0.5rem;
                }
            }
        }

        &:nth-child(even) {
            img {
                margin: 1rem;
                @include responsive(map-get($breakpoints, md)){
                    margin-right: 0.5rem;

                } 
                @include responsive(map-get($breakpoints, sm)){
                    margin-left: 0.5rem;
                }
            }
        }
    }

    &-desc {
        margin-left: 2rem;
        font-size: 1.5rem;


        @include responsive(map-get($breakpoints, md)){
            font-size: 1.2rem;
            margin-top: 1rem;
            margin-bottom: 2rem;
        } 

        @include responsive(map-get($breakpoints, sm)){
            font-size: 1.2rem;
            margin-top: 1rem;
            margin-bottom: 2rem;
        }
    }
}

.about {

    @include responsive(map-get($breakpoints, sm)){
        display: flex;
        flex-direction: column-reverse;
    }

    label{
        margin-bottom: 0;
        @include responsive(map-get($breakpoints, sm)){
            margin-bottom: 0.5rem;
        }
    }
    &-image {
        width: 100%;
        border-radius: 2rem;
        overflow: hidden;

        img {
            width: 100%;
            @include responsive(map-get($breakpoints, sm)){
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
            }
        }
    }
}

.projects{
    .project-holder{
        flex-wrap: wrap;
    }
}

.products {
    background-color: $platinum;
    border-radius: 2rem;
    padding: 4rem 3rem;
    color: $grayscale-900;


    @include responsive(map-get($breakpoints, sm)){
        padding: 2rem 1.2rem 1.2rem;
        .section-hero{
            margin-top: 0;
        }
    }

    .product-content {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        align-items: stretch;

        @include responsive(map-get($breakpoints, md)){
            flex-direction: row;
        }
    }

    .product-item {
        border: 1px solid $grayscale-200;
        padding: 1.5rem;
        border-radius: 2rem;
        width: calc(33.33% - 22px);

        @include responsive(map-get($breakpoints, md)){
            width: calc(50% - 16px);
        }


        @include responsive(map-get($breakpoints, sm)){
            width: 100%;
        }

        h3 {
            font-size: 1.6rem;
            margin-top: 1rem;
            margin-bottom: 4rem;
        }

        p {
            margin-bottom: 0;
        }
    }

    .product-hero-title {
        img {
            margin-right: 1rem;
            transition: 0.3s ease-in-out;
            @include responsive(map-get($breakpoints, sm)){
                margin-bottom: 1rem;
            }
        }
    }

    .btn {
        img {
            filter: invert(0%) sepia(3%) saturate(0%) hue-rotate(336deg) brightness(94%) contrast(106%);
        }
    }
}


.what-we-do {
    &-content {
        position: relative;
    }
}

.slider-progress {
    position: relative;
    height: 3px;
    margin-top: 10px;
    background-color: #ddd;
}

.slider-progress-bar {
    height: 100%;
    background-color: #00bcd4;
    transition: width 0.3s;
}


.custom-cursor {
    background-color: $platinum;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    mix-blend-mode: difference;
    pointer-events: none;
    transition: 0.3s ease-in-out;
    &::after {
        content: 'DRAG';
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 1.2rem;
        font-weight: bold;
    }
}

.blog{
    .blog-holder{
        @include responsive(map-get($breakpoints, md)){
            flex-direction: row;
        }
    }
}


@keyframes rotate-image {
    from { transform:  rotate(0);}
    to { transform:  rotate(360deg);}
}