$platinum: #C9D0D5;
$white: #fff;


$grayscale-900: #09090A;
$grayscale-800: #232425;
$grayscale-700: #3C3D3F;
$grayscale-600: #555659;
$grayscale-500: #6E7073;
$grayscale-400: #87898C;
$grayscale-300: #A1A3A5;
$grayscale-200: #BBBCBE;
$grayscale-100: #D6D6D7;
$grayscale-50: #EFEFF0;
