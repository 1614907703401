@import "../colors.scss";
@import '../brakepoint.scss';

@import '../mixins.scss';

.blog {
    &-holder {
        gap: 32px;
        flex-wrap: wrap;

        @include responsive(map-get($breakpoints, sm)) {
            flex-direction: column;
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        width: calc(33.33% - 22px);


        @include responsive(map-get($breakpoints, xxl)) {
            width: calc(50% - 16px);
        }

        @include responsive(map-get($breakpoints, xl)) {
            width: calc(50% - 16px);
        }

        @include responsive(map-get($breakpoints, lg)) {
            width: 100%;
        }

        @include responsive(map-get($breakpoints, md)) {
            width: 100%;
        }

        @include responsive(map-get($breakpoints, sm)) {
            width: 100%;
        }

        p{
            color: $grayscale-400;
        }

        h3 {
            margin-top: 0.8rem;
            min-height: 10rem;
            margin-bottom: 0.5rem;
            @include responsive(map-get($breakpoints, sm)){
                min-height: auto;
            }
        }

        &-img {
            border: 1px solid $grayscale-800;
            width: 100%;
            height: 20vw;
            border-radius: 2rem;
            overflow: hidden;
            margin-bottom: 1.5rem;

            @include responsive(map-get($breakpoints, xxl)) {
                height: 30vw;
            }

            @include responsive(map-get($breakpoints, xl)) {
                height: 40vw;
            }


            @include responsive(map-get($breakpoints, lg)) {
                height: 50vw;
            }

            @include responsive(map-get($breakpoints, sm)) {
                height: 60vw;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-content {
            width: 100%;
        }
    }
}

.single-blog-template {

    .col-2 {
        padding-right: 10vw;

        @include responsive(map-get($breakpoints, md)) {
            padding-right: 0;
        }
    }

    .hero-image {
        width: 100%;
        height: 40vw;


        @include responsive(map-get($breakpoints, md)) {
            height: 60vw;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .blog-content {
        @include responsive(map-get($breakpoints, md)) {
            flex-direction: column-reverse;
        }

        .details {


            hr {
                background-color: $grayscale-800;
                border: none;
                padding: 0;
                margin: 0;
                height: 2px;
                margin: 1.2rem 0;
            }
        }

        .reading {
            gap: 5px 10px;
            margin-bottom: 1rem;

            @include responsive(map-get($breakpoints, md)) {
                margin-bottom: 1rem;
            }
        }

        &-text {
            img {
                width: 100%;
                height: auto;
                margin-top: 2rem;
                margin-bottom: 2rem;
                border-radius: 1.74rem;
            }

            @include responsive(map-get($breakpoints, md)) {
                margin-top: 2rem;
            }
        }

    }


}