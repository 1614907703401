@mixin underlined-link {
    position: relative;
    text-decoration: none;

    &.link-icon {
        &::before {
            margin-left: 2.3rem;
            margin-top: 0.9px;
        }
    }

    &.link-padding {
        padding: 6px 0;

        &::before {
            top: 6px;
        }
    }

    &.large {
        &::before {
            margin-top: 1.5px;
            margin-left: 1.5px;
        }
    }


    &::before {
        content: attr(data-underlined-link);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        color: $white;
        text-decoration: underline;
        text-decoration-skip-ink: auto;
        text-underline-offset: 4px;
        text-decoration-thickness: 2px;
        clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);
        transition: clip-path .5s cubic-bezier(.86, 0, .07, 1);
    }

    &:hover,
    &:focus {
        &::before {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
    }
}
