@import './colors.scss';

@mixin position-center($text-align: center) {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	text-align: $text-align;
}

.loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $grayscale-900;
	z-index: 9999;
	// transition: 0.3s ease-in-out;
}

.loading-text {
	@include position-center;
	width: 100%;
	height: 100px;
	line-height: 100px;

	span {
		display: inline-block;
		margin: 0 5px;
		color: $white;
		font-family: 'Quattrocento Sans', sans-serif;

		@for $i from 0 through 6 {
			&:nth-child(#{$i + 1}) {
				filter: blur(0px);
				animation: blur-text 0.3s (#{$i/5})+s infinite linear alternate;
			}
		}
	}
}

.fade-out {
    transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
	visibility: hidden;
	opacity: 0;
    transition: transform 1s ease-in-out, opacity 0.8s ease-in-out, visibility 0.01s ease-out 0.6s;
    .loading-text {
        opacity: 0;
        transition: 0.6s ease-in-out;
    }
}

@keyframes blur-text {
	0% {
		filter: blur(0px);
	}

	100% {
		filter: blur(4px);
	}
}