@import '../colors.scss';
@import '../brakepoint.scss';

nav {
    .info {
        @include responsive(map-get($breakpoints, sm)) {
            width: 100%;
        }
    }
}



.nav {
    z-index: 100;
    mix-blend-mode: difference;
    position: relative;
    padding: 30px 0;

    &.opened {
        position: absolute;
        top: 0;
        left: 0;
    }

    .burger {
        color: $white;
        transition: 0.3s ease-in-out;

        .circle {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
            width: 10px;
            height: 10px;
            border-radius: 200px;
            background-color: $white;
            mix-blend-mode: difference;

            &-close {
                &::after {
                    content: '';
                }
            }
            }
        }
    }
}

.space.show {
    height: 105px;
    width: 100vw;
}

.menu {
    display: flex;
    align-items: flex-end;
    background-color: $grayscale-900;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    height: 0;
    opacity: 0;
   

    &.opened {
        opacity: 1;
        height: 100vh;
        transition: height 0.3s ease-in-out, opacity 0.4s ease-in-out;

        .menu-inner {
            opacity: 1;
        }
    }

    &.closed {
        opacity: 0;
        height: 0vh;
        transition: height 0.3s ease-in-out, opacity 0.2s ease-in-out;

        @include responsive(map-get($breakpoints, sm)) {
            transition: height 0.3s ease-in-out, opacity 0.1s ease-in-out;
        }


        .menu-inner {
            opacity: 0;
        }
    }

    ul {
        list-style: none;

        @include responsive(map-get($breakpoints, sm)) {
            width: 100%;
            margin-top: 2rem;
        }

        li {
            a {
                font-size: 7rem;
                line-height: 7rem;
                opacity: 0.4;

                &:hover {
                    opacity: 1;
                }

                @include responsive(map-get($breakpoints, md)) {
                    font-size: 4rem;
                    line-height: 4rem;

                }

                @include responsive(map-get($breakpoints, sm)) {
                    font-size: 4rem;
                    line-height: 4rem;
                    opacity: 1;

                }
            }

            .menu-sub {
                display: flex;
                align-items: flex-end;

                .label {
                    line-height: 100%;
                    margin-bottom: 18px;
                    margin-left: 8px;
                }
            }
        }
    }

    &-inner {
        padding-bottom: 3rem;
        opacity: 0;
        transition: 0.8s ease-in-out;

        @include responsive(map-get($breakpoints, md)) {
            flex-direction: row;
        }

        @include responsive(map-get($breakpoints, sm)) {
            padding-bottom: 6rem;
            flex-direction: column;

        }

        .info{
            @include responsive(map-get($breakpoints, md)) {
                width: fit-content
    
            }
            @include responsive(map-get($breakpoints, sm)) {
                width: 100%;
    
            }
           
        }



        .social-media {

            .label {
                display: block;
            }

            >div {
                max-width: 11vw;

                @include responsive(map-get($breakpoints, sm)) {
                    max-width: 100%;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 0.4rem;
                }
            }

            a {
                padding: 2px;

                &:not(:last-child) {
                    &::after {
                        content: ',';
                        display: block;
                        margin-left: 2px;
                        font-size: 1.2rem;
                        line-height: 1.1rem;

                    }
                }

                &::before {
                    top: 1.4px;
                    left: 1.4px;
                }

                &:hover {
                    color: $grayscale-200;

                    span {
                        border-bottom: 1px solid;
                    }
                }

                span {
                    border-bottom: 1px solid transparent;
                    padding: 2px 0;
                    transition: 0.3s ease-in-out;
                }
            }
        }
    }
}