@import "../colors.scss";
@import '../brakepoint.scss';
@import '../components/projectitem.scss';

.project-wrapper {
    .project-holder {
        gap: 32px;
        flex-wrap: wrap;

        @include responsive(map-get($breakpoints, sm)) {
            flex-direction: column;
        }

    }


    &>h1 {
        @include responsive(map-get($breakpoints, md)) {
            width: 75%;
        }

        @include responsive(map-get($breakpoints, sm)) {
            width: 98%;
        }
    }
}

.projects-template {
    .project-item {
        width: calc(50% - 16px);

        &-img {
            height: 44vw;

            img {
                object-fit: contain;
                object-position: bottom right;
            }
        }

    }
}


.single-project-template {
    .project-title {
        padding-bottom: 1rem;
    }

    .hero-image {
        width: 100%;
        height: 40vw;

        @include responsive(map-get($breakpoints, md)) {
            height: 60vw;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .categories {
        padding-top: 0.9rem;

        @include responsive(map-get($breakpoints, md)) {
            margin-bottom: 3rem;
        }
    }

    .metrics-items {
        display: flex;
        margin-top: 2rem;
        gap: 1.5rem;
        flex-wrap: wrap;

        &-item {
            width: 30%;
            border: 1px solid $grayscale-800;
            border-radius: 1.74rem;
            padding: 2.5rem 1rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;

            @include responsive(map-get($breakpoints, md)) {
                width: 100%;
            }

            h5 {
                font-size: 2.28rem;
                width: 100%;
                text-align: center;
            }

            p {
                text-align: center;
                margin-bottom: 0;
                margin-top: 0.5rem;
                width: 100%;
                color: $grayscale-400;
                line-height: inherit;
            }
        }
    }

    .item-line {
        border-bottom: 1px solid $grayscale-800;
        padding-bottom: 1rem;
    }

    .images {
        gap: 32px;

        div {
            border-radius: 2rem;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}