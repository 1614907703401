@import '../colors.scss';
@import '../brakepoint.scss';


.page-title {
    .label {
        margin-bottom: 0;
    }

    h1 {
        @include responsive(map-get($breakpoints, md)) {
            margin-bottom: 2rem;
        }

        br {
            @include responsive(map-get($breakpoints, md)) {
                display: none;
            }
        }
    }
}

.contact-items {
    
    a, p, span:not(.label) {
        font-size: 1.4vw;
        span{
            font-size: inherit;
        }

        @include responsive(map-get($breakpoints, md)){
            font-size: 20px;
        }
    }

    .contact-item:not(:last-child) {
        margin-bottom: 2rem;
    }

    .social-media {

        >div {
            max-width: 60%;

            @include responsive(map-get($breakpoints, sm)) {
                max-width: 100%;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 0.4rem;
            }
        }

        a {
            padding: 2px;

            &:not(:last-child) {
                &::after {
                    content: ',';
                    display: block;
                    margin-left: 2px;
                    font-size: 1.2rem;
                    line-height: 1.1rem;

                }
            }

            &:hover {
                color: $grayscale-200;

                span {
                    border-bottom: 1px solid;
                }
            }

            span {
                border-bottom: 1px solid transparent;
                padding: 2px 0;
                transition: 0.3s ease-in-out;
            }
        }
    }
}

.partnership {
    background-color: $platinum;
    color: $grayscale-900;
    padding: 2.28rem;
    border-radius: 1.71rem;
    margin-top: 6.85rem;

    >.d-flex {

        @include responsive(map-get($breakpoints, md)) {
            flex-direction: column;
        }
    }


    .btn {

        @include responsive(map-get($breakpoints, md)) {
            margin: 0 auto;
            width: 100%;
            margin-left: 0;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
        }

    }

    @include responsive(map-get($breakpoints, sm)) {
        margin-top: 2rem;
    }

    .d-flex {

        @include responsive(map-get($breakpoints, sm)) {
            flex-direction: column;
        }
    }

    a {
        @include responsive(map-get($breakpoints, sm)) {
            width: 100%;
            display: flex;
            flex-direction: row !important;
            align-items: c;
            margin-top: 1rem;
        }
    }



    img {
        @include responsive(map-get($breakpoints, sm)) {
            margin-bottom: 1rem;
        }
    }

    .btn {
        img {
            margin: 0;
        }
    }

    .desc {
        padding: 0 3.64rem;
        margin-top: 1rem;

        @include responsive(map-get($breakpoints, sm)) {
            padding: 0;
        }
    }

}