@font-face {
    font-family: 'inity-medium';
    font-display: swap;
    src: url('../fonts/PPNeueMontreal-Medium.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/PPNeueMontreal-Medium.woff') format('woff'),
        /* Pretty Modern Browsers */
        url('../fonts/PPNeueMontreal-Medium.ttf') format('truetype'),
        /* Safari, Android, iOS */
}

@font-face {
    font-family: 'inity-regular';
    font-display: swap;
    src: url('../fonts/PPNeueMontreal-Regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/PPNeueMontreal-Regular.woff') format('woff'),
        /* Pretty Modern Browsers */
        url('../fonts/PPNeueMontreal-Regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
}

@font-face {
    font-family: 'inity-light';
    font-display: swap;
    src: url('../fonts/PPNeueMontreal-Light.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/PPNeueMontreal-Light.woff') format('woff'),
        /* Pretty Modern Browsers */
        url('../fonts/PPNeueMontreal-Light.ttf') format('truetype'),
        /* Safari, Android, iOS */
}

body {
    font-size: 1.1vw;
    font-family: 'inity-regular';
}

h1 {
    text-align: left;
    letter-spacing: -.035em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 6vw;
    font-weight: 500;
    line-height: 100%;
    font-family: 'inity-medium';

    span,
    i {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        letter-spacing: inherit;

    }
}

h2 {
    letter-spacing: -.14vw;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 3.7vw;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 2rem;
    font-family: 'inity-medium';

    span,
    i {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        letter-spacing: inherit;

    }
}

h3 {
    letter-spacing: -.009em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2vw;
    font-weight: 500;
    line-height: 1.5;
    font-family: 'inity-medium';

    span,
    i {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        letter-spacing: inherit;

    }
}

h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    font-family: 'inity-medium';

    span,
    i {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        letter-spacing: inherit;

    }
}

.uppercase {
    text-transform: uppercase;
}

.h-5 {
    font-size: 2rem;
    margin-bottom: 1.3rem;
    font-family: 'inity-medium';

    @include responsive(map-get($breakpoints, md)) {
        font-size: 4.5vw;
    }
}

p,
span,
a {
    margin-bottom: 0;
    line-height: 1.65;
    // font-size: 1rem;
}

p {
    margin-bottom: 1rem;
}

ol {
    padding-left: 2.3rem;
    font-size: inherit;

    li {
        line-height: 1.65;
        margin-bottom: 0.5rem;

        // font-size: 1rem;
        &::marker {
            font-size: 1.2rem;
        }

        p,
        span,
        i {
            font-size: inherit;
            line-height: inherit;
            padding-left: 0.5rem;
            display: block;
            font-size: inherit;

            @include responsive(map-get($breakpoints, md)) {
                font-size: 18px;
            }
        }
    }
}


@include responsive(map-get($breakpoints, xl)) {
    h1 {
        font-size: 10vw;
    }

    h2 {
        font-size: 7vw;
    }

    p,
    span,
    a {
        font-size: 18px;
    }

    .h-5 {
        font-size: 6.5vw;
    }
}


@include responsive(map-get($breakpoints, md)) {
    h1 {
        font-size: 10vw;
    }

    h2 {
        font-size: 7vw;
    }

    p,
    span,
    a {
        font-size: 18px;
    }

    .h-5 {
        font-size: 6.5vw;
    }
}