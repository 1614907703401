@import '../colors.scss';
@import '../brakepoint.scss';

footer {
    margin-top: 8rem;
    padding-bottom: 4.57rem;
    @include responsive(map-get($breakpoints, sm)){
        padding-bottom: 3rem;
        margin-top: 4rem;
    }

    div{
        @include responsive(map-get($breakpoints, sm)){
            width: 100%;
            max-width: 100%;
        }
    }

    .d-flex{
        @include responsive(map-get($breakpoints, sm)){
            flex-direction: column;  
            gap: 2rem;          
        }
    }

    .location {
        p {
            line-height: 2.5vw;
            margin-bottom: 0;

            @include responsive(map-get($breakpoints, md)){
                line-height: 1.65;
            }
        }
    }

    .social-media {

        >div {
            max-width: 10vw;
            @include responsive(map-get($breakpoints, sm)){
                max-width: 100%;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 0.4rem;
            }
        }

        a {
            padding: 2px;

            &:not(:last-child) {
                &::after {
                    content: ',';
                    display: block;
                    margin-left: 2px;
                    font-size: 1.2rem;
                    line-height: 1.1rem;

                }
            }

            &:hover {
                color: $grayscale-200;

                span {
                    border-bottom: 1px solid;
                }
            }

            span {
                border-bottom: 1px solid transparent;
                padding: 2px 0;
                transition: 0.3s ease-in-out;
            }
        }
    }

    .footer-line {
        background-color: $grayscale-800;
        border: none;
        padding: 0;
        margin: 0;
        height: 2px;
        width: 100%;
        margin-bottom: 4.57rem;
        @include responsive(map-get($breakpoints, sm)){
            margin-bottom: 3rem;
        }
    }

    .copy {
        font-size: 0.8vw;
        color: $grayscale-200;

        a {
            color: $grayscale-200;

            &:hover {
                color: $white;
            }
        }

        @include responsive(map-get($breakpoints, md)){
            font-size: 16px;
        }

        @include responsive(map-get($breakpoints, sm)){
            gap: 1rem;
            flex-direction: column-reverse;
            margin-top: 2rem;
        }
    }
}