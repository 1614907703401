.project-item {
    display: flex;
    flex-direction: column;
    width: calc(33.33% - 22px);
    border: 1px solid $grayscale-800;
    border-radius: 2rem;
    overflow: hidden;
    height: initial;


    &:hover{
        background-color: var(--element-color);
    }
    

    @include responsive(map-get($breakpoints, md)) {
        width: calc(50% - 17px);
        min-height: 56vw;
    }

    @include responsive(map-get($breakpoints, sm)) {
        width: 100%;
    }


    h3 {
        margin-top: 1.3rem;
        transition: 0.3s ease-in-out;
    }

    &-img {
        width: 100%;
        height: 30vw;
        overflow: hidden;
        margin-bottom: 1.5rem;

        @include responsive(map-get($breakpoints, sm)) {
            height: 80vw;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-content {
        width: 100%;
    }

    &-content {
        width: 100%;
        padding: 0 1.5rem 1.5rem 1.5rem;

        h3 {
            margin-top: 0;
            margin-bottom: 1rem;
        }
    }


}