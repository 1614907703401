 @import './colors.scss';
@import './brakepoint.scss';

@import './typo.scss';
@import './mixins.scss';


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    // font-size: 16px;

}

body {

    background-color: $grayscale-900;
    // background-image: url('../images/dots.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-blend-mode: difference;
    background-position: top;
    padding: 0;
    margin: 0;
    width: 100vw;
    overflow-x: hidden;
    font-family: 'inity-regular', sans-serif;
    color: $white;

    @include responsive(map-get($breakpoints, md)) {
        background-size: auto;
    }

    @include responsive(map-get($breakpoints, sm)) {
        background-size: auto;
    }

}

html.lenis {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
    position: fixed;
}

.lenis.lenis-scrolling iframe {
    pointer-events: none;
}

::-webkit-scrollbar {
    width: 0;
}

a {
    text-decoration: none;
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: center;
    width: fit-content;
    color: $white;
    -webkit-tap-highlight-color: transparent;

    &:focus {
        color: $grayscale-600;
    }

    .icon {
        margin-right: 0.3rem;
        display: block;
        width: 2rem;
    }
}

.link-icon-left {
    // padding: 0.8rem 0;
    display: flex;

    &:hover {
        color: $grayscale-200;

        span {
            border-bottom: 1px solid;
        }
    }

    .icon {
        margin-right: 0.5rem;
        display: block;
        width: 2rem;
    }

    span {
        border-bottom: 1px solid transparent;
        padding: 2px 0;
        transition: 0.3s ease-in-out;
        font-size: 1.3rem;
    }
}

[class*="-template"] {
    transition: 0.3s ease-in-out;
}

.container {
    width: 100vw;
    padding-left: 7rem;
    padding-right: 7rem;

    @include responsive(map-get($breakpoints, md)) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @include responsive(map-get($breakpoints, sm)) {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        overflow-x: hidden
    }



}

.w-100 {
    width: 100%;

    @include responsive(map-get($breakpoints, md)) {
        width: 100%;
    }
}

.col-1 {
    width: 16.66%;

    @include responsive(map-get($breakpoints, md)) {
        width: 100%;
    }
}

.col-1-2 {
    width: calc(16.66%/2);

    @include responsive(map-get($breakpoints, md)) {
        width: 100%;
    }
}

.col-2 {
    width: 33.33%;

    @include responsive(map-get($breakpoints, md)) {
        width: 100%;
    }
}

.col-3 {
    width: 50%;

    @include responsive(map-get($breakpoints, md)) {
        width: 100%;
    }
}

.col-4 {
    width: 66.66%;

    @include responsive(map-get($breakpoints, md)) {
        width: 100%;
    }
}

.col-5 {
    width: 83.33%;

    @include responsive(map-get($breakpoints, md)) {
        width: 100%;
    }
}

.col-6 {
    width: 100%;
}


.label {
    text-transform: uppercase;
    font-size: 0.9vw;
    color: $grayscale-200;
    margin-bottom: 0.5rem;

    @include responsive(map-get($breakpoints, md)) {
        font-size: 15px;
    }
}

.btn {
    border: 1px solid transparent;
    border-radius: 800px;
    padding: 8px 16px;
    min-width: fit-content;
    transition: 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
    }
}

.btn.black {
    border: 1px solid $grayscale-900;
    color: $grayscale-900;

    img {
        transition: 0.3s ease-in-out;
    }

    &:hover {
        background-color: $grayscale-900;
        color: $white;

        img {
            mix-blend-mode: difference;
            filter: invert(100%) sepia(6%) saturate(23%) hue-rotate(210deg) brightness(105%) contrast(107%);
        }
    }
}

.btn.white {
    border: 1px solid $white;
    color: $white;

    img {
        transition: 0.3s ease-in-out;
    }

    &:hover {
        background-color: $white;
        color: $grayscale-900;

        img {
            mix-blend-mode: difference;
        }
    }
}

.btn.white.icon-right {
    img {
        margin-left: 1rem;
    }
}

.btn.black.icon-right {
    img {
        margin-left: 1rem;
    }
}


.m-none {
    @include responsive(map-get($breakpoints, xl)) {
        display: none;
    }

    @include responsive(map-get($breakpoints, md)) {
        display: none;
    }
}

.m-show {
    display: none;

    @include responsive(map-get($breakpoints, xl)) {
        display: block;
    }

    @include responsive(map-get($breakpoints, md)) {
        display: block;
    }
}


.d-flex {
    display: flex;
}

.m-column {
    @include responsive(map-get($breakpoints, md)) {
        flex-direction: column;
    }
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.alg-center {
    align-items: center;
}

.alg-top {
    align-items: flex-start;
}

.alg-bottom {
    align-items: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.gap-05 {
    gap: 0.5rem;
}

.gap-1 {
    gap: 1rem;
}

.gap-2 {
    gap: 2rem;
}

.mt-1 {
    margin-top: 1vw;
}

.mt-2 {
    margin-top: 2vw;
}

.mt-3 {
    margin-top: 3vw;
}

.mt-4 {
    margin-top: 4vw;
}

.mt-5 {
    margin-top: 5vw;
}

.mt-7 {
    margin-top: 7vw;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 1vw;
}

.mb-2 {
    margin-bottom: 2vw;
}

.mb-3 {
    margin-bottom: 3vw;
}

.mb-4 {
    margin-bottom: 4vw;
}

.mb-5 {
    margin-bottom: 5vw;
}

.mr-1 {
    margin-right: 1rem;
}

.ml-0 {
    margin-left: 0;
}

.pt-1 {
    padding-top: 1rem;
}

.pt-1-5 {
    padding-top: 1.5rem;
}



.img-holder {
    border-radius: 1.74rem;
    overflow: hidden;
}

.scale-image {
    width: 4.1vw;

    @include responsive(map-get($breakpoints, md)) {
        width: 7.1vw;
    }

    @include responsive(map-get($breakpoints, sm)) {
        width: 9.1vw;
    }

}


.underlined-link {
    @include underlined-link();
}


